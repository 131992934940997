<template>
  <div>
    <div class="page-header d-flex align-items-center justify-content-between">
      <h3 class="page-header-title">Resolution Center</h3>
      <base-button title="New Ticket" action="primary" @click-btn="ui.showNewTicketModal = true" />
    </div>

    <!--  Page tabs  -->
    <div class="page-tabs d-flex justify-content-between align-items-center"
      v-if="isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT')">
      <ul class="tab-list">
        <li :class="{ active: isTab('all') }" @click="setTab('all')">
          All Tickets
        </li>
        <li :class="{ active: isTab('open') }" @click="setTab('open')">
          Open <span class="tab-list-item--count">{{ counts.open }}</span>
        </li>
        <li :class="{ active: isTab('pending') }" @click="setTab('pending')">
          Pending <span class="tab-list-item--count">{{ counts.pending }}</span>
        </li>
        <li :class="{ active: isTab('closed') }" @click="setTab('closed')">
          Closed <span class="tab-list-item--count">{{ counts.closed }}</span>
        </li>
        <li :class="{ active: isTab('solved') }" @click="setTab('solved')">
          Solved <span class="tab-list-item--count">{{ counts.solved }}</span>
        </li>
      </ul>
    </div>

    <!--  Page Content  -->
    <div class="page-content p-normal">
      <!--   Page Type   -->
      <div class="row">
        <div class="col-md-6">
          <div class="d-flex w-100 align-items-baseline">
            <div class="page-filtration__search" :class="{ 'page-filtration__search_active': ui.showSearchField }">
              <button class="btn page-filtration__button"
                :class="{'page-filtration__button_disabled': ui.showSearchField}" @click="toggleSearchField">
                <img src="@/assets/icons/icon-search.svg" alt="" />
              </button>
              <div class="form-group">
                <label for="searchField">
                  <input
                    type="text"
                    id="searchField"
                    class="form-control"
                    @focusout="hideInput"
                    v-model="searchField"
                    ref="searchField"
                    placeholder="Requester Name"
                    @input="search"
                  />
                </label>
              </div>
            </div>
            <button class="filter-btn" :class="{ active: filterType('all') }" @click="selectType('all')">
              All
            </button>
            <button class="filter-btn" :class="{ active: filterType('payment') }" @click="selectType('payment')">
              Payment
            </button>
            <button class="filter-btn" :class="{ active: filterType('technical') }" @click="selectType('technical')">
              Technical
            </button>
            <button class="filter-btn" :class="{ active: filterType('general') }" @click="selectType('general')">
              General
            </button>
          </div>
        </div>
      </div>

      <page-spinner v-if="ui.loading" />

      <!--  Page Content Table  -->
      <div class="table-block" v-else>
        <all-tickets-table :tickets="tickets" />
      </div>
      <Paging
        class="mt-3"
        :page="currentPage"
        :paging="paging"
        @limitChanged="pagingUpdated"
        @pageChanged="pagingUpdated"
      ></Paging>
    </div>

    <transition name="component-fade" mode="out-in">
      <resolution-center-new-ticket
        v-if="ui.showNewTicketModal"
        @close="ui.showNewTicketModal = false"
        @success="getTickets"
      />
    </transition>
  </div>
</template>

<script>
import AllTicketsTable from "./includes/AllTicketsTable";
import PageSpinner from "../../components/pageSpinner";
import Paging from "@/components/Paging";
import ResolutionCenterNewTicket from "./modals/ResolutionCenterNewTicket.vue";
import debounce from "@/utils/debounce";
import BaseButton from '../../components/BaseButton.vue';

export default {
  name: "ResolutionCenter",
  components: {
    PageSpinner,
    AllTicketsTable,
    Paging,
    ResolutionCenterNewTicket,
    BaseButton
  },
  data() {
    return {
      searchField: "",
      tabName: "all",
      type: "all",
      tickets: [],
      counts: {
        open: 0,
        pending: 0,
        closed: 0,
        solved: 0
      },
      ui: {
        loading: false,
        showSearch: false,
        showNewTicketModal: false,
        showSearchField: false
      },
      currentPage: 1,
      paging: {
        offset: 0,
        limit: 10,
        total: 0
      },
      search: debounce(() => {
        this.getTickets();
      }, 350)
    };
  },
  methods: {
    // Filters section
    setTab(tabName) {
      this.tabName = tabName;
      this.paging.offset = 0;
      this.getTickets();
    },
    selectType(type) {
      this.type = type;
      this.paging.offset = 0;
      this.getTickets();
    },
    isTab(tabName) {
      return this.tabName === tabName;
    },
    filterType(type) {
      return this.type === type;
    },
    pagingUpdated(paging) {
      this.paging = paging;
      this.currentPage = paging.currentPage;
      this.getTickets();
    },

    // Api section
    getTickets() {
      this.ui.loading = true;

      let formData = {
        query: this.searchField.trim(),
        status: this.tabName.toLowerCase(),
        type: this.type.toLowerCase(),
        offset: this.paging.offset,
        limit: this.paging.limit
      };

      this.$http
        .get("/api/v1/resolution-center", { params: formData })
        .then(res => {
          this.tickets = res.data.tickets;
          this.paging = res.data.paging;
        })
        .catch(err => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.loading = false;
        });
    },
    getStats() {
      if (this.isGranted("ROLE_CEO") || this.isGranted("ROLE_CEO_ASSISTANT")) {
        this.$http
          .get("/api/v1/resolution-center/stats")
          .then(res => {
            this.counts = res.data;
          })
          .catch(err => {
            this.alertError(err.response.statusText);
          });
      }
    },
    toggleSearchField() {
      this.ui.showSearchField = true;
      this.$refs.searchField.focus();
    },
    hideInput() {
      this.$refs.searchField.blur();
      this.ui.showSearchField = false;
    }
  },
  beforeMount() {
    this.getStats();
    this.getTickets();
    if (this.$route.hash.length) {
      this.ui.showNewTicketModal = true
    }
  }
};
</script>

<style lang="scss" scoped>
.page-content {
  padding-top: 0;
}
.ml-auto {
  margin-left: auto !important;
}
.search-wrapper {
  max-width: 100%;
  border-radius: 18px;
  background: none;

  input {
    border-radius: 18px;
    color: #808080;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 12px;
    font-weight: normal;
    padding-left: 33px;
  }

  @media (min-width: 768px) {
    max-width: 180px;
  }
}

.btn-toggle-search {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  margin-top: 23px;

  img {
    width: 24px;
    height: 24px;
    opacity: 0.7;
  }
}

.filter-btn {
  border: 1px solid #cccccc;
  background: none;
  border-radius: 15px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  padding: 6px 12px;
  margin-left: 8px;
  transition: all 0.3s;
  margin-top: 21px;
  transition: all 0.3s;

  &.active {
    color: #ffffff;
    background-color: #23232e;
    border: 1px solid #23232e;
  }

  &:hover {
    border: 1px solid #808080;
    background-color: #f2f2f2;
    color: #000000;
  }
}

::v-deep .table-block {
  margin-top: 21px;
  table {
    min-width: 1400px;
    margin-top: 20px;

    @media (min-width: 1024px) {
      min-width: initial;
      margin-top: 0;
    }

    thead {
      tr {
        th {
          opacity: 1;
          color: rgba(0, 0, 0, 0.7);
          font-size: 13px;
          letter-spacing: 0;
          line-height: 20px;
        }
      }
    }

    tr:nth-child(even) td:first-child {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    tr:nth-child(even) td:last-child {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    tbody {
      tr {
        transition: all 0.4s;
        cursor: pointer;
        &:hover {
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
        }

        &:nth-child(even) {
          background: none !important;
        }

        td {
          height: 84px;
          color: #000000;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 21px;

          &:nth-child(n + 2) {
            padding-left: 0;
          }

          .user-avatar {
            &--title {
              display: inline-block;
              color: #000000;
              font-size: 14px;
              letter-spacing: 0;
              line-height: 21px;
              margin-left: 9px;
            }
          }

          &.ticket-td {
            width: 450px;
            .ticket {
              &-title {
                color: #000000;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 21px;
                max-height: 25px;
                width: 400px;
                max-width: 400px;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              &-status {
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 18px;
                padding: 4px 9px;
                width: fit-content;
                margin-top: 6px;
                border-radius: 2px;
                text-transform: capitalize;

                &.payment {
                  color: #00ab34;
                  background-color: rgba(0, 171, 52, 0.1);
                }

                &.general {
                  color: #4c4c4c;
                  background-color: rgba(146, 146, 146, 0.1);
                }

                &.technical {
                  color: #ff7300;
                  background: rgba(255, 115, 0, 0.1);
                }
              }
            }
          }

          .status {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 75px;
            height: 24px;
            font-size: 11px;
            letter-spacing: 0;
            line-height: 17px;
            border-radius: 14px;
            width: fit-content;
            padding: 4.5px 12.5px;
            text-transform: uppercase;

            &.open {
              color: #ffffff;
              border: 1px solid #065ae4;
              background-color: #065ae4;
            }

            &.pending {
              color: rgba(0, 0, 0, 0.7);
              border: 1px solid rgba(0, 0, 0, 0.7);
              border-radius: 12px;
            }

            &.solved {
              color: #ffffff;
              border: 1px solid #00ab34;
              background-color: #00ab34;
            }

            &.closed {
              color: #ffffff;
              background-color: #929292;
            }
          }

          .assigned-users {
            &-other {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 32px;
              height: 32px;
              border-radius: 50%;
              border: 1px solid #cccccc;
              background-color: #d8d8d8;
              color: #000000;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 18px;
              text-align: center;
              margin-left: -5px;
            }

            div {
              img {
                width: 31px;
                height: 31px;
                object-fit: cover;
                border-radius: 50%;
                border: 1px solid #cccccc;
              }
              &:nth-child(n + 2) {
                margin-left: -5px;
              }
            }
          }
        }
      }
    }
  }
}
.page-filtration__button {
  position: relative;
  z-index: 2;
  top: -1px;

  &.page-filtration__button_disabled {
    cursor: unset;
    pointer-events: none;
  }

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.page-filtration__search {
  position: relative;
  min-width: 20px;
  display: flex;
  align-items: center;
  height: 30px;
  min-height: 30px;

  .form-group {
    position: absolute;
    opacity: 0;
    z-index: -1;
    top: 0;
    width: 0;
    transition: all 0.3s;
    height: 32px;
    min-height: 32px;

    input {
      height: 32px;
      min-height: 32px;
      border-radius: 16px;
      padding-left: 35px;
      font-sise: 13px;

      &::placeholder {
        font-size: 13px;
      }
    }
  }

  &_active {
    min-width: 160px;
    .form-group {
      z-index: 0;
      min-width: 160px;
      opacity: 1;
    }
  }
}
</style>
