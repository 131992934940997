<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>New Ticket</h3>
      </div>
      <div class="modal-body" v-if="ui.loading">
        <page-spinner />
      </div>
      <div class="modal-body" v-if="!ui.loading">
        <div class="modal-body-container border-bottom-0">
          <div class="form-group row w-100 full-width">
            <label for="inquiryField" class="col-sm-2 col-form-label"
              >Inquiry Type</label
            >
            <div class="col-sm-8">
              <multiselect
                placeholder="Select Inquiry Type"
                class="w-100 full-width"
                id="inquiryField"
                track-by="value"
                label="label"
                v-model="formData.inquiryType"
                :allowEmpty="false"
                :options="inquiryTypes"
                :class="{
                  selected: Object.keys(formData.inquiryType).length > 0
                }"
                :show-labels="false"
              >
              </multiselect>
            </div>
          </div>
          <div class="form-group row w-100 full-width mt-3">
            <label for="fieldContent" class="col-sm-2 col-form-label"
              >Message</label
            >
            <div class="col-sm-10">
              <ckeditor
                :editor="editor"
                v-model="formData.msg"
                id="fieldContent"
                :config="editorConfig"
              >
              </ckeditor>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-start" v-if="!ui.loading">
        <base-button title="Submit" action="secondary-default" @click-btn="createTicket"
                     :disabled="ui.saving || formData.msg.trim() == ''" :loading="ui.saving" />
        <base-button title="Cancel" action="secondary" @click-btn="$emit('close')" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PageSpinner from "@/components/pageSpinner";
import BaseButton from '../../../components/BaseButton.vue';

export default {
  name: "ResolutionCenterNewTicket",
  components: { Multiselect, PageSpinner, BaseButton },
  data() {
    return {
      ui: {
        saving: false,
        loading: false
      },
      formData: {
        inquiryType: {},
        msg: ""
      },
      inquiryTypes: [],
      editor: ClassicEditor,
      editorConfig: {
        toolbar: ["bold", "|", "italic", "|", "link"]
      }
    };
  },
  methods: {
    getTypes() {
      this.ui.loading = true;
      this.$http
        .get("/api/v1/resolution-center/create")
        .then(res => {
          this.inquiryTypes = res.data.types;
          if (this.$route.hash === '#it') {
            this.formData.inquiryType = res.data.types.filter((el) => el.value === "technical")[0]
          } else if (this.$route.hash === '#hr') {
            this.formData.inquiryType = res.data.types.filter((el) => el.value === 'general')[0]
          } else {
            this.formData.inquiryType = res.data.types[0];
          }
        })
        .catch(err => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.loading = false;
        });
    },
    createTicket() {
      this.ui.saving = true;
      let formData = new FormData();
      formData.append("type", this.formData.inquiryType.value);
      formData.append("msg", this.formData.msg);
      this.$http
        .post("/api/v1/resolution-center/create", formData)
        .then(res => {
          this.alertSuccess(res.data.message);
          this.$emit("success");
          this.$emit("close");
        })
        .catch(err => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.saving = false;
        });
    }
  },
  created() {
    this.getTypes();
  }
};
</script>

<style lang="scss" scoped>
label {
  opacity: 0.7;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}
::v-deep .multiselect {
  min-height: 30px;
  max-height: 30px;
  min-width: 180px;
  width: 100%;
  @media screen and (max-width: 568px) {
    max-width: 50%;
    min-width: 100%;
  }

  &.multiselect--active {
    .multiselect__tags {
      transition: all 0.3s;
      border: 1px solid rgba(38, 111, 229, 0.5);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }

    .multiselect__select {
      padding: 4px 20px 4px 8px;
    }
  }

  &__tags {
    min-height: 32px;
    max-height: 32px;
    padding: 6px 40px 0 8px;
    transition: all 0.3s;
    border-radius: 4px;

    &:hover {
      border: 1px solid rgba(38, 111, 229, 0.2);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__select {
    min-height: 32px;
    max-height: 32px;
    padding: 4px 8px 4px 20px;

    &:before {
      top: 0%;
      position: relative;
      right: 0px;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }

  &__single {
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    margin: 0;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }

  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all 0.3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }

    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}

::v-deep {
  .ck.ck-editor__main > .ck-editor__editable {
    min-height: 200px !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
  .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}
</style>
