var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',_vm._l((_vm.tickets),function(ticket){return _c('tr',{key:ticket.id,on:{"click":function($event){return _vm.$router.push({ name: 'ticket_view', params: { id: ticket.id } })}}},[_c('td',[_vm._v(_vm._s(ticket.id))]),_c('td',[_c('div',{staticClass:"d-flex user-avatar align-items-center"},[_c('v-avatar',{attrs:{"src":_vm.agentPhoto(ticket.requester.avatar),"rounded":"","size":32}}),_c('span',{staticClass:"user-avatar--title"},[_vm._v(" "+_vm._s(ticket.requester.name)+" ")])],1)]),_c('td',{staticClass:"ticket-td"},[_c('div',{staticClass:"ticket"},[_c('div',{staticClass:"ticket-title"},[_vm._v(" "+_vm._s(_vm.showRawContent(ticket.name, 70))+" ")]),_c('div',{staticClass:"ticket-status",class:{
                payment: ticket.type === 'payment',
                general: ticket.type === 'general',
                technical: ticket.type === 'technical'
              }},[_vm._v(" "+_vm._s(ticket.type ? ticket.type : 'N/A')+" ")])])]),_c('td',[_vm._v(_vm._s(_vm._f("timeAgo")(ticket.createdAt)))]),_c('td',[_vm._v(_vm._s(_vm._f("timeAgo")(ticket.updatedAt)))]),_c('td',[_c('div',{staticClass:"status",class:{
              open: ticket.status === 'open',
              pending: ticket.status === 'pending',
              closed: ticket.status === 'closed',
              solved: ticket.status === 'solved'
            }},[_vm._v(" "+_vm._s(ticket.status)+" ")])]),_c('td',[_c('div',{staticClass:"d-flex assigned-users"},[_vm._l((_vm.getAssignees(ticket.assignedTo)),function(assignee,index){return _c('div',{key:index},[_c('img',{directives:[{name:"tippy",rawName:"v-tippy"}],attrs:{"src":_vm.agentPhoto(assignee.avatar),"alt":"assignee","content":assignee.name}})])}),(ticket.assignedTo.length > 2)?_c('div',[_c('div',{staticClass:"assigned-users-other"},[_vm._v(" +"+_vm._s(ticket.assignedTo.length - 2)+" ")])]):_vm._e()],2)])])}),0)]),(_vm.tickets.length === 0)?_c('div',{staticClass:"col-12 modal-alert modal-alert_advice text-center"},[_vm._v(" No Results Found ")]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Id")]),_c('th',[_vm._v("Requester")]),_c('th',[_vm._v("Ticket")]),_c('th',[_vm._v("Created")]),_c('th',[_vm._v("Last Activity")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Assigned to")])])])}]

export { render, staticRenderFns }