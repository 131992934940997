<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th>Id</th>
          <th>Requester</th>
          <th>Ticket</th>
          <th>Created</th>
          <th>Last Activity</th>
          <th>Status</th>
          <th>Assigned to</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="ticket in tickets"
          :key="ticket.id"
          @click="
            $router.push({ name: 'ticket_view', params: { id: ticket.id } })
          "
        >
          <td>{{ ticket.id }}</td>
          <td>
            <div class="d-flex user-avatar align-items-center">
              <v-avatar
                :src="agentPhoto(ticket.requester.avatar)"
                rounded
                :size="32"
              />
              <span class="user-avatar--title">
                {{ ticket.requester.name }}
              </span>
            </div>
          </td>
          <td class="ticket-td">
            <div class="ticket">
              <div class="ticket-title">
                {{ showRawContent(ticket.name, 70) }}
              </div>
              <div
                class="ticket-status"
                :class="{
                  payment: ticket.type === 'payment',
                  general: ticket.type === 'general',
                  technical: ticket.type === 'technical'
                }"
              >
                {{ ticket.type ? ticket.type : 'N/A' }}
              </div>
            </div>
          </td>
          <td>{{ ticket.createdAt | timeAgo }}</td>
          <td>{{ ticket.updatedAt | timeAgo }}</td>
          <td>
            <div
              class="status"
              :class="{
                open: ticket.status === 'open',
                pending: ticket.status === 'pending',
                closed: ticket.status === 'closed',
                solved: ticket.status === 'solved'
              }"
            >
              {{ ticket.status }}
            </div>
          </td>
          <td>
            <div class="d-flex assigned-users">
              <div v-for="(assignee, index) in getAssignees(ticket.assignedTo)" :key="index">
                <img
                  :src="agentPhoto(assignee.avatar)"
                  alt="assignee"
                  v-tippy
                  :content="assignee.name"
                />
              </div>
              <div v-if="ticket.assignedTo.length > 2">
                <div class="assigned-users-other">
                  +{{ ticket.assignedTo.length - 2 }}
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="col-12 modal-alert modal-alert_advice text-center"
      v-if="tickets.length === 0"
    >
      No Results Found
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "AllTicketsTable",
  props: {
    tickets: {
      type: Array,
      required: true
    }
  },
  methods: {
    showRawContent(value, limit) {
      if (value) {
        let filteredData = value.replace(/<\/?[^>]+>/gi, " ");
        return filteredData.split("&nbsp;").join(" ").substring(0, limit) + "..";
      }
      return ".."
    },
    getAssignees(assienees) {
      let assignee = []
      assienees.map(function(value, key) {
        if (key <= 1) {
          assignee.push(value);
        }
      });

      return assignee;
    }
  },
  filters: {
    timeAgo(value) {
      if (value) {
        return moment(String(value)).fromNow();
      }
    }
  }
};
</script>
